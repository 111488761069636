<template>
    <div class="contact page">
        <b-container fluid="xl">
            <b-row class="contact__data">
                <b-col cols="12">
                    <h1>Kapcsolat</h1>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="contact__data-item">
                        <h2>NT Élelmiszertermelő és Kereskedelmi Kft.</h2>
                        <div class="data">
                            <div>Tel.: <a href="tel:+3676431628">+36-76-431-628</a></div>
                            <div>Fax: +36-76-560-109</div>
                            <div><a href="mailto:info@ntkft.hu">info@ntkft.hu</a></div>
                        </div>                        
                    </div>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="contact__data-item">
                        <h2>Olaj- és melléktermék-értékesítés</h2>
                        <div class="data">
                            <div>Árajánlat kérés: <a href="mailto:ntsales@ntkft.hu">ntsales@ntkft.hu</a></div>                            
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="contact__map-wrap">
                <b-col cols="12">
                    <GmapMap
                        :center="{lat: 46.68943, lng:19.85479}"
                        :zoom="13"                        
                        :options="mapStyle"                        
                        style="width: 100%; height: 555px"
                    >
                        <!-- <GmapMarker :position="{lat: 46.68943, lng:19.85479}" /> -->
                        <gmap-info-window 
                            @closeclick="window_open=false" 
                            :opened="window_open" 
                            :position="infowindow"
                            :options="{
                                pixelOffset: {
                                    width: 0,
                                    height: -35
                                }
                            }"
                        >
                            <div class="gmap-infowindow__content">
                                <div class="name">NT Kft.</div>
                                <div class="address">6100 Kiskunfélegyháza, Kunsági Éden út 1.</div>
                                <div class="btn-wrap">
                                    <div>N 46.68943, E 19.85479</div>
                                    <a href="https://goo.gl/maps/5KAtdysRsLfCooZH7" target="_blank" class="button ml-2">
                                        <img src="@/assets/images/icons/map.svg"  />Térkép
                                    </a>
                                </div>
                                
                            </div>
                        </gmap-info-window>  
                    </GmapMap>
                </b-col>
            </b-row>
        </b-container>                 
        <Socialnetwork  /> 
    </div>
</template>

<script>
import Vue from 'vue'
import Socialnetwork from '@/components/layout/Socialnetwork'

import {gmapApi} from 'vue2-google-maps'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps,{
  load: {
    key: 'AIzaSyAOc5kpt9op4-abzkaeJDJyfjgkwkbmEhg',
    libraries: 'places', 
  }});

export default {
    data(){
        return {
            infowindow: {lat: 46.68943, lng: 19.85479},
            window_open: true,
            mapStyle : 
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                        { visibility: "off" }
                    ]
                }
            
        }
    },
    components:{
        Socialnetwork
    },
    computed: {
        google: gmapApi
    },
    mounted(){
            this.setMetaTags({
                title: 'Kunsági Éden - Kapcsolat', 
                desc: '', 
                keywords: '', 
                ogType: 'website', 
                ogTitle: 'Kunsági Éden - Kapcsolat',
                ogImage: null, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:''
            })
    },
    methods:{
        openWindow () {            
            this.window_open = true
        }
    }
}
</script>